import * as React from "react"
import { IconAnchorButton } from "gatsby-interface"
import { FaLinkedin, FaFacebook, FaTwitter, FaEnvelope } from "react-icons/fa"
import { useTracker, SegmentEventType } from "../../utils/analytics"

const brandColors = {
  twitter: "#1D9BF0",
  linkedIn: "#0073B1",
  facebook: "#1877F2",
  email: "#7026B9",
}

const objectToParams = (object: { [key: string]: string }) =>
  `?` +
  Object.entries(object)
    .filter(([_, value]) => Boolean(value))
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join(`&`)

const ShareMenuItem = ({
  href,
  icon,
  shareText,
  hoverColor,
}: {
  href: string
  icon: React.ReactElement
  shareText: string
  hoverColor: string
}) => {
  const { trackSegment } = useTracker()
  return (
    <IconAnchorButton
      icon={icon}
      size="S"
      target="_blank"
      rel="noopener noreferrer"
      variant="GHOST"
      tone="NEUTRAL"
      href={href}
      title={shareText}
      css={theme => ({
        "&&": {
          borderBottom: "none",
          color: theme.colors.grey[50],
        },
        "&:hover, &:focus": {
          color: `${hoverColor}`,
        },
        "> svg": {
          "&:hover, &:focus": {
            stroke: theme.colors.white,
          },
        },
      })}
      onClick={() =>
        trackSegment({
          type: SegmentEventType.Track,
          event: "Blog Social Share Button Clicked",
          properties: {
            platform: shareText,
            sharedUrl: href,
          },
        })
      }
    >
      {shareText}
    </IconAnchorButton>
  )
}

type SocialShareProps = {
  url: string
  title: string
  mailToSubject?: string
  mailToBody?: string
}

export const SocialShare = ({
  url,
  title,
  mailToSubject,
  mailToBody,
}: SocialShareProps) => {
  const socialItems = [
    {
      href: `https://twitter.com/share${objectToParams({
        url: url,
        text: title,
      })}`,
      icon: <FaTwitter />,
      shareText: "Share on Twitter",
      hoverColor: brandColors.twitter,
    },
    {
      href: `https://www.linkedin.com/shareArticle${objectToParams({
        mini: `true`,
        url: url,
        title: title,
      })}`,
      icon: <FaLinkedin />,
      shareText: "Share on LinkedIn",
      hoverColor: brandColors.linkedIn,
    },
    {
      href: `https://www.facebook.com/sharer.php${objectToParams({
        u: url,
        t: title,
      })}`,
      icon: <FaFacebook />,
      shareText: "Share on Facebook",
      hoverColor: brandColors.facebook,
    },
  ]

  if (mailToSubject && mailToBody) {
    socialItems.push({
      href: `mailto:?subject=${mailToSubject}&mailToBody=${mailToBody}`,
      icon: <FaEnvelope />,
      shareText: "Share via Email",
      hoverColor: brandColors.email,
    })
  }

  return (
    <React.Fragment>
      {socialItems.map(({ href, icon, shareText, hoverColor }, i) => (
        <ShareMenuItem
          key={i}
          href={href}
          icon={icon}
          shareText={shareText}
          hoverColor={hoverColor}
        />
      ))}
    </React.Fragment>
  )
}

export type SocialShareLeftSideStickyContainerProps = {
  children: React.ReactNode
  topOffset?: string
}

export const SocialShareLeftSideStickyContainer = ({
  children,
  topOffset,
}: SocialShareLeftSideStickyContainerProps) => (
  <div
    css={theme => ({
      position: "relative",
      paddingTop: 0,

      [theme.mediaQueries.desktop]: {
        position: "absolute",
        height: "100%",
        left: 0,
        top: 0,
        transform: `translateX(-${theme.space[11]})`,
        paddingTop: topOffset,
      },
    })}
  >
    <div
      css={theme => ({
        "& > a:not(:last-of-type)": {
          marginRight: theme.space[3],
        },

        [theme.mediaQueries.desktop]: {
          display: "inline-grid",
          position: "sticky",
          top: theme.space[15],
          gap: theme.space[3],

          "& > a:not(:last-of-type)": {
            marginRight: 0,
          },
        },
      })}
    >
      {children}
    </div>
  </div>
)
