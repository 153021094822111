import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Global } from "@emotion/core"

import Layout from "../../layout"
import SiteHead from "../../head"
import Title from "../../components/hero/title"
import Author from "../../components/blog/author"
import FlexibleContentBlock from "../../components/blog/flexible-content-block"
import Meta from "../../components/blog/meta"
import PrevAndNext from "../../components/blog/prev-and-next"
import config from "../../config"
import {
  SocialShare,
  SocialShareLeftSideStickyContainer,
} from "../../components/social-share"
import { defaultPaddingHorizontal } from "../../utils/styles"
import { globalStyles } from "../../utils/styles/global"

export function Head(props) {
  const post = props.data.wpPost
  const title = post.seoMetadata.seoTitle ?? post.title
  const description = post.rawExcerpt?.text
  const imagePath = post.seoMetadata?.socialMediaImage?.resize?.src
  const image = imagePath ? `${config.url}${imagePath}` : null
  const canonical = post.seoMetadata?.canonicalLink

  return (
    <SiteHead {...props} title={title} description={description} image={image}>
      {canonical && <link rel="canonical" href={canonical} />}
    </SiteHead>
  )
}

export default function BlogPostPage({
  location,
  data: { wpPost: post },
  pageContext: { next, prev },
}) {
  return (
    <Layout pathname={location.pathname}>
      <Global styles={globalStyles} />
      <main
        id={`gatsby-skip-here`}
        className="post docSearch-content"
        css={theme => [
          {
            marginLeft: `auto`,
            marginRight: `auto`,
            paddingBottom: theme.space[10],
            paddingTop: theme.space[10],
            [theme.mediaQueries.phablet]: {
              display: "grid",
              gridTemplateColumns: "1fr",
              maxWidth: "42rem",
              width: `90%`,
            },
            [theme.mediaQueries.desktop]: {
              display: "grid",
              gridColumnGap: theme.space[13],
              gridTemplateColumns: "1fr 15rem",
              maxWidth: "68rem",
              paddingBottom: theme.space[13],
              paddingTop: theme.space[13],
            },
            [theme.mediaQueries.hd]: {
              gridTemplateColumns: "1fr 18rem",
            },
          },
          defaultPaddingHorizontal(theme),
        ]}
      >
        <Title
          css={theme => ({
            alignSelf: "end",
            [theme.mediaQueries.phablet]: {
              maxWidth: "90%",
            },
            [theme.mediaQueries.desktop]: {
              marginBottom: 0,
            },
          })}
        >
          {post.title}
        </Title>
        <div
          css={theme => ({
            order: 0,
            [theme.mediaQueries.desktop]: {
              alignSelf: "end",
              gridColumn: 2,
              gridRow: 1,
              marginTop: 48,
              position: "relative",
            },
          })}
        >
          <Author
            name={post.author?.node?.name || `Gatsby Contributor`}
            date={post.date}
            slug={post.author?.node?.slug}
            image={post.author?.node?.authorInfo?.avatar}
          />
        </div>
        <section
          className="post-body main-body"
          css={theme => ({
            position: "relative",
            gridColumn: 1,
            minWidth: 0,
            paddingTop: theme.space[8],
            ".gatsby-resp-iframe-wrapper": {
              height: 0,
              overflow: `hidden`,
              paddingBottom: `56.25%`,
              position: `relative`,
              width: `100%`,
              iframe: {
                height: `100%`,
                left: 0,
                position: `absolute`,
                top: 0,
                width: `100%`,
              },
            },
            ".gatsby-embed-twitter": {
              maxHeight: `1000px`,
              overflowY: `auto`,
            },
          })}
        >
          {post.featuredImage?.node?.gatsbyImage && (
            <GatsbyImage
              alt={post.featuredImage.node.altText}
              image={post.featuredImage.node.gatsbyImage}
            />
          )}
          {post.flexibleContent?.blocks?.map((block, index) => {
            return (
              <div
                key={index}
                css={theme => ({
                  marginBottom: theme.space[6],
                  paddingTop: theme.space[6],
                })}
              >
                <FlexibleContentBlock block={block} />
              </div>
            )
          })}

          <SocialShareLeftSideStickyContainer topOffset="3.5rem">
            <SocialShare
              url={`https://www.gatsbyjs.com${location.pathname}`}
              title={`Check out this Gatsby blog post: ${post.title}`}
              mailToSubject={`Check out this blog post from Gatsby`}
              mailToBody={`Check out this blog post "${post.title}" from Gatsby - https://www.gatsbyjs.com${location.pathname}`}
            />
          </SocialShareLeftSideStickyContainer>
        </section>
        <div
          css={theme => ({ [theme.mediaQueries.desktop]: { gridColumn: 2 } })}
        >
          <Meta post={post} />
        </div>
        <div
          css={theme => ({ [theme.mediaQueries.desktop]: { gridColumn: 1 } })}
        >
          <PrevAndNext prev={prev} next={next} />
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPage($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      featuredImage {
        node {
          id
          altText
          gatsbyImage(width: 600, layout: CONSTRAINED)
        }
      }
      rawExcerpt {
        text
      }
      author {
        node {
          ...AuthorInformation
        }
      }
      seoMetadata {
        canonicalLink
        publishedAt
        seoTitle
        socialMediaImage {
          resize(width: 1200, height: 630, format: JPG, fit: COVER) {
            src
          }
        }
      }
      ctaRightRail {
        ctaLink
        ctaText
        ctaTitle
      }
      tags {
        nodes {
          id
          name
          slug
        }
      }
      date(formatString: "MMMM Do, YYYY")
      ...FlexibleContentBlocks
    }
  }
`
